import React, { useEffect, useState } from "react";
import Popup from "../layout/Popup";

const CreateRequest = ({ data, onSubmit, handleClose }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    localStorage.setItem("req_name", name);
    localStorage.setItem("req_email", email);

    onSubmit({
      songId: data.songId,
      date: data.date,
      status: data.status,
      artistUserId: data.artistUserId,
      name: name,
      email: email,
      memo: description,
    });
  };

  const fetchExistingValues = () => {
    let name = localStorage.getItem("req_name");
    let email = localStorage.getItem("req_email");

    name && setName(name);
    email && setEmail(email);
  };

  useEffect(() => {
    fetchExistingValues();
  }, []);

  return (
    <Popup dark={true} popupstyles="p-0 m-0" setFunc={handleClose}>
      <form
        onSubmit={handleSubmit}
        className="flex flex-col  gap-5  h-[100%] pb-10 sm:pb-5"
      >
        <h1
          className="text-4xl font-bold bg-black text-white w-full px-2 sm:px-5 pt-7 pb-4 shadow-md border border-[#303947]
        flex justify-between items-center
        "
        >
          <h2 className="text-lg xs:text-xl  lg:text-3xl xl:text-4xl">
            Send Request
          </h2>
          <span className=" w-[60%] sm:max-w-[70%] truncate sm:text-[14px] text-[12px] bg-green-200 flex  line-clamp-1 xl:hidden  items-center">
            <span className="truncate">{data?.song}&nbsp;&nbsp;</span> :
            <span className="truncate">
              &nbsp;&nbsp;
              {data?.artist}
            </span>
          </span>
          <span className="sm:text-[14px] text-[12px] bg-green-200 hidden xl:flex items-end line-clamp-1">
            {data.song.slice(0, 15)}
            {" : " + data.artist}
          </span>
        </h1>

        {/* Covering Title and Message Feild  */}
        <div className="flex flex-col gap-4 flex-1  w-full p-6">
          {/* Title Feild  */}
          <div className="flex flex-col text-white font-semibold w-full gap-1">
            <label htmlFor="name">Name</label>
            <input
              id="name"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              className=" w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium  outline-none "
              required
            />
          </div>

          <div className="flex flex-col text-white font-semibold w-full gap-1">
            <label htmlFor="name">Email</label>
            <input
              placeholder="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              className=" w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium  outline-none "
            />
          </div>

          {/* Message Feild  */}
          <div className="flex flex-col text-white font-semibold w-full gap-1">
            <label htmlFor="message">Memo</label>
            <textarea
              placeholder="Memo"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium resize-none w-full h-40  outline-none "
            ></textarea>
          </div>
        </div>
        <button
          type="submit"
          className="border-red  bg-red text-white border-[3px] px-6 py-2 font-bold rounded mx-6 mb-6"
        >
          Send Request
        </button>
      </form>
    </Popup>
  );
};

export default CreateRequest;
