import React, { useState, useRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css"; // Import cropperjs styles
import "./app.css"; // Add custom styles for your app
import imageCompression from "browser-image-compression"; // Import the compression library
import { FadeLoader } from "react-spinners";
const App = ({ handleSubmit = () => {}, setModalOpen = () => {} }) => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [isHighResolution, setIsHighResolution] = useState(true); // State to track resolution validity
  const cropperRef = useRef(null);
  const [Loading, setLoading] = useState(false);
  // Function to convert base64 to a File
  function base64ToFile(base64, filename) {
    const arr = base64.split(",");
    const mime = arr[0].match(/:(.*?);/)[1]; // Get the mime type
    const bstr = atob(arr[1]); // Decode base64 string
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Check if the file is an image
    if (file && file.type.match(/^image\//)) {
      // Check for file size (e.g., minimum 1MB = 1048576 bytes)
      if (file) {
        const reader = new FileReader();

        reader.onload = () => {
          const img = new Image();
          img.onload = () => {
            // Check the image dimensions (width and height)
            const imageWidth = img.width;
            const imageHeight = img.height;

            setImage(reader.result); // Set the image for cropping
            setIsHighResolution(true); // Set as high resolution
          };

          img.src = reader.result; // Set the image source
        };

        reader.readAsDataURL(file);
      } else {
        setImage(null);
        setIsHighResolution(false);
      }
    } else {
      alert("Invalid file type! Please select an image file.");
    }
  };

  // Handle crop button click
  const handleCrop = async () => {
    setLoading(true);
    const cropper = cropperRef.current?.cropper;
    // setModalOpen(false);
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();

      // Convert the cropped canvas to a Blob
      croppedCanvas.toBlob(async (blob) => {
        if (blob) {
          try {
            // Compress the cropped image Blob
            const compressedImage = await imageCompression(blob, {
              maxSizeMB: 1, // Set the max size to 1MB (adjust as needed)
              maxWidthOrHeight: 800, // Limit the width/height to 800px (optional)
            });

            // Convert the compressed Blob back to a data URL
            const compressedDataUrl = await new Promise((resolve) => {
              const reader = new FileReader();
              reader.onloadend = () => resolve(reader.result);
              reader.readAsDataURL(compressedImage);
            });

            // Set the cropped and compressed image
            setCroppedImage(compressedDataUrl);

            // Convert the compressed data URL to a File object
            const compressedFile = base64ToFile(
              compressedDataUrl,
              "cropped_compressed_image.png"
            );

            // Handle the submit with the compressed file
            handleSubmit(compressedFile);
            setLoading(false);
          } catch (error) {
            console.error("Error during image compression:", error);
            setLoading(false);
          }
        }
      }, "image/png"); // Convert canvas to a Blob with the 'image/png' MIME type
    }
  };

  // Handle restore button click
  const handleRestore = () => {
    const cropper = cropperRef.current?.cropper;
    if (cropper) {
      cropper.reset();
      setCroppedImage(null);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        id="fileInput"
      />
      {image && (
        <div>
          <Cropper
            src={image}
            style={{ height: 400, width: "100%" }}
            guides={false}
            ref={cropperRef}
            cropBoxResizable={true}
            draggable={false}
            cropBoxMovable={true}
            viewMode={1}
          />
        </div>
      )}
      {image ? (
        <button
          onClick={handleCrop}
          className="rounded-[8px] px-4 py-2 mt-6 bg-[#2e86de]"
        >
          {Loading ? "Loading...." : "Crop"}
        </button>
      ) : null}

      {!isHighResolution && (
        <div style={{ color: "red", marginTop: "10px" }}>
          <p>Please upload an image with a minimum size of 720px x 1080px.</p>
        </div>
      )}
    </div>
  );
};

export default App;
