import React, { useState, useEffect } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useNavigation,
} from "react-router-dom";

import { RxHamburgerMenu, RxCross1 } from "react-icons/rx";
import { BiUserCircle, BiSolidPlaylist } from "react-icons/bi";
import { IoExitOutline } from "react-icons/io5";
import authStore from "../../store/auth";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import { FaCaretDown, FaChevronLeft, FaFacebookF } from "react-icons/fa";
import { AiFillInstagram } from "react-icons/ai";
import { TbClockHour12, TbPlaylist } from "react-icons/tb";
import { IoIosSearch } from "react-icons/io";
import { TiContacts } from "react-icons/ti";
import { PLACEHOLDER_IMAGE } from "../../constants";
import { toDataURL } from "../../utils/toDataUrl";
import { MdPlayLesson } from "react-icons/md";
const Navbar = ({ setToggle }) => {
  // const navigation = useNavigation();
  const {
    state: { isAuthenticated, user },
    logout,
  } = authStore;

  const location = useLocation();

  const navigate = useNavigate();
  //open resposive navbar layout
  const [open, setOpen] = useState(false);

  const [userImage, setUserImage] = useState("");

  const fetchUserImage = () => {
    toDataURL(user.image + "?x=1")
      .then((res) => {
        setUserImage(res);
      })
      .catch((error) => {
        console.error("Fetching image failed:", error.message);
      });
  };

  useEffect(() => {
    user && user?.image && fetchUserImage();
  }, [user, userImage]);
  //changes navbar background color as you scroll down
  const [navbarColor, setNavbarColor] = useState("bg-transparent");

  // Show Edit Profile Section
  const [editProfile, setEditProfile] = useState(false);

  let icons = [
    `${process.env.PUBLIC_URL}/assets/icons/Live_request_icon.svg`,
    `${process.env.PUBLIC_URL}/assets/icons/Campaign_icon.svg`,
    `${process.env.PUBLIC_URL}/assets/icons/Templates_icon.svg`,
    `${process.env.PUBLIC_URL}/assets/icons/Audience_data_icon.svg`,
    `${process.env.PUBLIC_URL}/assets/icons/search_icon.svg`,
    `${process.env.PUBLIC_URL}/assets/icons/Playlist.svg`,
  ];

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 40) {
      setNavbarColor("bg-black/90");
    } else {
      setNavbarColor("bg-transparent");
    }
  };
  const handleLogout = () => {
    logout();
    navigate("/login");
  };
  const EditProfile = () => {
    navigate("/settings");

    setEditProfile(false);
  };
  return (
    <nav
      // className={`${navbarColor} fixed z-10 flex items-center gap-14 p-8 `}
      className={`z-10  text-[12px]  bg-[#22252A]  fixed  flex   h-screen `}
    >
      <FaChevronLeft
        className="z-[50]  text-white absolute -right-0 rounded-l-full top-10 pl-1 py-2 pr-2 text-3xl  bg-red cursor-pointer lg:hidden block"
        onClick={() => setToggle(false)}
      />
      <div className="flex-col flex justify-between border-r border-slate-300 pb-10 sm:pb-0 ">
        <div />
        <div className="mb-5 gap-3 w-10  ">
          <div className="gap-3 flex text-sm rotate-90 text-white ">
            <Link
              to="/contact"
              className="rotate-180 hover:text-red "
              onClick={() => setToggle(false)}
            >
              Contact
            </Link>
            <Link
              to="/about"
              className="rotate-180 hover:text-red "
              onClick={() => setToggle(false)}
            >
              About
            </Link>
          </div>
          <div className="gap-3 mt-24 items-center flex flex-col">
            <a
              href="https://www.facebook.com/jakotawassmusic?mibextid=LQQJ4d"
              className=" "
              onClick={() => setToggle(false)}
              target="_blank"
            >
              <FaFacebookF
                className="text-white hover:text-black hover:bg-white hover:border-white border border-red rounded-lg px-1 py-1 "
                size={25}
              />
            </a>
            <a
              href="https://www.instagram.com/jakotawassmusic?igsh=MWFhcGJnbTF4YXFnaA%3D%3D&utm_source=qr"
              target="_blank"
              className=""
              onClick={() => setToggle(false)}
            >
              <AiFillInstagram
                className="text-white hover:text-black hover:bg-white hover:border-white border border-red rounded-lg "
                size={25}
              />
            </a>
          </div>
        </div>
      </div>
      <div className="flex-col flex justify-between  pb-10 sm:pb-0 w-full ">
        <div className="p-4">
          <Link to="/" className="flex-1" onClick={() => setToggle(false)}>
            <img
              alt="logo"
              className="w-[150px] object-cover px-5"
              src={process.env.PUBLIC_URL + "/assets/icons/logo-xl.png"}
            />
          </Link>
        </div>

        {/* nav for desktop and tablet size */}
        {isAuthenticated ? (
          <div className="h-full py-5 content-between grid ">
            <div className="flex flex-col gap-5  text-[12px]">
              <Link
                to="/playlist"
                className={`${
                  location.pathname.includes("playlist")
                    ? "text-white"
                    : "text-slate-400"
                }  flex items-center pt-2 `}
                onClick={() => setToggle(false)}
              >
                <div
                  className={`${
                    location.pathname.includes("playlist")
                      ? "border-2 rounded-e-lg border-red h-full"
                      : "border-2 rounded-e-lg border-black h-full"
                  } mr-2`}
                />
                <img
                  alt="logo"
                  className={`w-22 object-cover ${
                    location.pathname.includes("/playlist")
                      ? "text-white"
                      : "text-slate-400"
                  } mr-2`}
                  src={icons[5]}
                />
                Playlist
              </Link>
              <Link
                to="/live-requests"
                onClick={() => setToggle(false)}
                className={`${
                  location.pathname.includes("/live-requests")
                    ? "text-white"
                    : "text-slate-400"
                }  flex items-center pt-2 `}
              >
                <div
                  className={`${
                    location.pathname.includes("/live-requests")
                      ? "border-2 rounded-e-lg border-red h-full"
                      : "border-2 rounded-e-lg border-black h-full"
                  } mr-2`}
                />
                <img
                  alt="logo"
                  className={`w-22 object-cover ${
                    location.pathname.includes("/live-requests")
                      ? "text-white"
                      : "text-slate-400"
                  } mr-2`}
                  src={icons[0]}
                />
                Live Request
              </Link>
              <Link
                to={
                  user?.subscription_active
                    ? "/campaign"
                    : { pathname: "/settings" }
                }
                state={{ myValue: "value" }}
                onClick={() => {
                  !user?.subscription_active &&
                    toast.error("Please activate your subscription!");

                  setToggle(false);
                }}
                className={`${
                  location.pathname.includes("campaign")
                    ? "text-white"
                    : "text-slate-400"
                }  flex items-center pt-2 `}
              >
                <div
                  className={`${
                    location.pathname.includes("campaign")
                      ? "border-2 rounded-e-lg border-red h-full"
                      : "border-2 rounded-e-lg border-black h-full"
                  } mr-2`}
                />
                <img
                  alt="logo"
                  className={`w-22 object-cover ${
                    location.pathname.includes("/campaign")
                      ? "text-white"
                      : "text-slate-400"
                  } mr-2`}
                  src={icons[1]}
                />
                Campaign
              </Link>
              <Link
                to={"/templates"}
                // onClick={() => {
                //   !user?.subscription_active &&
                //     toast.error("Please activate your subscription!");
                // }}
                onClick={() => setToggle(false)}
                className={`${
                  location.pathname.includes("templates")
                    ? "text-white"
                    : "text-slate-400"
                }  flex items-center pt-2 `}
              >
                <div
                  className={`${
                    location.pathname.includes("templates")
                      ? "border-2 rounded-e-lg border-red h-full"
                      : "border-2 rounded-e-lg border-black h-full"
                  } mr-2`}
                />
                <img
                  alt="logo"
                  className={`w-22 object-cover ${
                    location.pathname.includes("/templates")
                      ? "text-white"
                      : "text-slate-400"
                  } mr-2`}
                  src={icons[2]}
                />
                Templates
              </Link>
              <Link
                to={user?.subscription_active ? "/audience-data" : "/settings"}
                onClick={() => {
                  !user?.subscription_active &&
                    toast.error("Please activate your subscription!");
                  setToggle(false);
                }}
                state={{ myValue: "value" }}
                className={`${
                  location.pathname.includes("/audience-data")
                    ? "text-white"
                    : "text-slate-400"
                }  flex items-center pt-2 `}
              >
                <div
                  className={`${
                    location.pathname.includes("/audience-data")
                      ? "border-2 rounded-e-lg border-red h-full"
                      : "border-2 rounded-e-lg border-black h-full"
                  } mr-2`}
                />
                <img
                  alt="logo"
                  className={`w-22 object-cover ${
                    location.pathname.includes("/audience-data")
                      ? "text-white"
                      : "text-slate-400"
                  } mr-2`}
                  src={icons[3]}
                />
                Audience Data
              </Link>
              <Link
                to="/search"
                onClick={() => setToggle(false)}
                className={`${
                  location.pathname.includes("/search")
                    ? "text-white"
                    : "text-slate-400"
                }  flex items-center pt-2 `}
              >
                <div
                  className={`${
                    location.pathname.includes("/search")
                      ? "border-2 rounded-e-lg border-red h-full"
                      : "border-2 rounded-e-lg border-black h-full"
                  } mr-2`}
                />
                <img
                  alt="logo"
                  className={`w-22 object-cover ${
                    location.pathname.includes("/search")
                      ? "text-white"
                      : "text-slate-400"
                  } mr-2`}
                  src={icons[4]}
                />
                Search
              </Link>
              <Link
                to="/tutorials"
                onClick={() => setToggle(false)}
                className={`${
                  location.pathname.includes("/tutorials")
                    ? "text-white"
                    : "text-slate-400"
                }   flex items-center pt-2`}
              >
                <div
                  className={`${
                    location.pathname.includes("/tutorials")
                      ? "border-2 rounded-e-lg border-red h-full"
                      : "border-2 rounded-e-lg border-black h-full"
                  } mr-2`}
                />
                <MdPlayLesson
                  className={`${
                    location.pathname.includes("/tutorials")
                      ? "text-white"
                      : "text-slate-400"
                  } mr-2`}
                  size={18}
                />
                Tutorials
              </Link>
            </div>
            <div className="md:flex  flex flex-col gap-5   text-[12px] mx-2">
              {editProfile && (
                <Link
                  className="flex flex-col items-center gap-2 py-2 px-1 bg-black border border-slate-600  rounded-md text-center  text-white	"
                  onClick={() => setToggle(false)}
                >
                  <div className="flex gap-2 items-center ">
                    <img
                      className="h-10 w-10 rounded-full object-cover mr-2"
                      // src={user?.image}
                      src={userImage}
                      alt="profile"
                    />

                    {/* <img
                          className="h-[200px] w-[200px] rounded-full object-cover border-2 border-grey"
                          src={
                            selectedImage
                              ? URL?.createObjectURL(selectedImage)
                              : existingImage
                              ? existingImage
                              : PLACEHOLDER_IMAGE
                          }
                          alt="profile"
                          style={{
                            boxShadow: "0px 4px 35px rgba(251, 46, 51, 0.5)",
                          }}
                        /> */}
                    <label className="flex flex-col items-start ">
                      {" "}
                      {user?.firstName + " " + user?.lastName}
                      <p className="text-[8px]">{user?.email}</p>
                    </label>
                  </div>
                  <Link
                    to={"/settings"}
                    className="bg-red p-1 rounded w-full"
                    onClick={() => {
                      setToggle(false);
                      EditProfile();
                    }}
                    // onClick={EditProfile}
                  >
                    Edit Profile
                  </Link>
                </Link>
              )}
              <Link
                // to="/login"
                onClick={() => {
                  setEditProfile((e) => !e);
                }}
                className="flex items-center py-2 px-2 bg-black border border-slate-600  rounded-md text-center  text-white	"
              >
                <img
                  className="h-10 w-10 rounded-full object-cover mr-2"
                  //
                  src={userImage}
                  alt="profile"
                />
                <label className="flex items-center  justify-between">
                  {" "}
                  {user?.firstName + " " + user?.lastName}
                  <FaCaretDown className="text-white" size={20} />
                </label>
              </Link>
              <Link
                to={"/login"}
                type="button"
                className="flex items-center py-1 px-5 bg-red  rounded-md text-center w-full	text-white"
                onClick={() => {
                  handleLogout();
                  setToggle(false);
                }}
              >
                <IoExitOutline size={25} className="mr-2" />
                Logout
              </Link>
            </div>
          </div>
        ) : (
          <>
            {!location.pathname.includes("/audience-requests") && (
              <div className="h-screen  py-5 content-between grid relative">
                <div className="flex flex-col  gap-5  text-[12px]">
                  <Link
                    to="/search"
                    className={`${
                      location.pathname.includes("/search")
                        ? "text-white"
                        : "text-slate-400"
                    }  flex items-center pt-2 `}
                    onClick={() => setToggle(false)}
                  >
                    <div
                      className={`${
                        location.pathname.includes("/search")
                          ? "border-2 rounded-e-lg border-red h-full"
                          : "border-2 rounded-e-lg border-black h-full"
                      } mr-2`}
                    />
                    <img
                      alt="logo"
                      className={`w-22 object-cover ${
                        location.pathname.includes("/search")
                          ? "text-white"
                          : "text-slate-400"
                      } mr-2`}
                      src={icons[4]}
                    />
                    Search
                  </Link>
                  <Link
                    to="/about"
                    onClick={() => setToggle(false)}
                    className={`${
                      location.pathname.includes("/about")
                        ? "text-white"
                        : "text-slate-400"
                    }  flex items-center pt-2`}
                  >
                    <div
                      className={`${
                        location.pathname.includes("/about")
                          ? "border-2 rounded-e-lg border-red h-full"
                          : "border-2 rounded-e-lg border-black h-full"
                      } mr-2`}
                    />
                    <TbClockHour12
                      className={`${
                        location.pathname.includes("/about")
                          ? "text-white"
                          : "text-slate-400"
                      } mr-2 rotate-180`}
                      size={18}
                    />
                    About
                  </Link>
                  <Link
                    to="/contact"
                    onClick={() => setToggle(false)}
                    className={`${
                      location.pathname.includes("/contact")
                        ? "text-white"
                        : "text-slate-400"
                    }   flex items-center pt-2`}
                  >
                    <div
                      className={`${
                        location.pathname.includes("/contact")
                          ? "border-2 rounded-e-lg border-red h-full"
                          : "border-2 rounded-e-lg border-black h-full"
                      } mr-2`}
                    />
                    <TiContacts
                      className={`${
                        location.pathname.includes("/contact")
                          ? "text-white"
                          : "text-slate-400"
                      } mr-2`}
                      size={18}
                    />
                    Contact
                  </Link>
                  <Link
                    to="/tutorials"
                    onClick={() => setToggle(false)}
                    className={`${
                      location.pathname.includes("/tutorials")
                        ? "text-white"
                        : "text-slate-400"
                    }   flex items-center pt-2`}
                  >
                    <div
                      className={`${
                        location.pathname.includes("/tutorials")
                          ? "border-2 rounded-e-lg border-red h-full"
                          : "border-2 rounded-e-lg border-black h-full"
                      } mr-2`}
                    />
                    <MdPlayLesson
                      className={`${
                        location.pathname.includes("/tutorials")
                          ? "text-white"
                          : "text-slate-400"
                      } mr-2`}
                      size={18}
                    />
                    Tutorials
                  </Link>
                </div>
                <div className="flex w-[100%] mx-auto self-center items-center flex-col gap-3  text-[12px] text-white px-4">
                  <Link
                    to="/login"
                    onClick={() => setToggle(false)}
                    className="py-2 px-4 bg-red  rounded-md text-center 	w-full"
                  >
                    Log in
                  </Link>
                  <Link
                    to="/register"
                    onClick={() => setToggle(false)}
                    className="hover:bg-red hover:border-red  border rounded-md text-center py-2 px-4 w-full "
                  >
                    Sign up
                  </Link>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </nav>
  );
};

export default observer(Navbar);
