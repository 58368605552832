import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import General from "../component/settings/General";
import { PLACEHOLDER_IMAGE } from "../constants";
import Artist from "../component/settings/Artist";
import Tip from "../component/settings/Tip";
import { RxCross1 } from "react-icons/rx";
// import SubscriptionAndPayment from "../component/settings/SubscriptionAndPayment";
import CenterPopup from "../component/layout/CenterPopup";
import authStore from "../store/auth";
import subscriptionStore from "../store/subscription";
import { observer } from "mobx-react";
import moment from "moment/moment";
import { toast } from "react-toastify";
import { FaExternalLinkAlt } from "react-icons/fa";
import Popup from "../component/layout/Popup";
import ImageCropper from "../component/ImageCropper";
import { toDataURL } from "../utils/toDataUrl";
import { BounceLoader } from "react-spinners";

const Settings = (props) => {
  const navigate = useNavigate();
  const {
    state: { user },
    updateUser,
    uploadUserImage,
    resetAuthenticatedUserPassword,
    logout,
    loadUser,
  } = authStore;

  const { getSubscription, cancelSubscription } = subscriptionStore;

  const [imageLoading, setImageLoading] = useState(false);
  // const [showSubs, setShowSubs] = useState(false);
  const [existingImage, setExistingImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleSelectImage = async (e) => {
    setImageLoading(true);
    setSelectedImage(e);

    const formData = new FormData();
    formData.append("image", e);

    await uploadUserImage(formData, user.userId, setImageLoading);
    await loadUser();

    setModalOpen(false);
  };

  const [showSubscribe, setShowSubscribe] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    username: "",
    instagram: "",
    facebook: "",
    website: "",
    number: "",
    venmo: "",
    bandName: "",
    venmoLink: "",
    cashAppLink: "",
    zelleLink: "",
    pdf: "",
  });
  const location = useLocation();

  const [selected, setSelected] = useState("General");

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  useEffect(() => {
    if (user) {
      setImageLoading(true);
      setFormData({
        ...formData,
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        number: user.number ? user.number : "",
        facebookHandle: user.facebookHandle ? user.facebookHandle : "",
        bandName: user.bandName ? user.bandName : "",
        bio: user.bio ? user.bio : "",
        instagram: user.instagram ? user.instagram : "",
        facebook: user.facebook ? user.facebook : "",
        website: user.website ? user.website : "",
        instaHandle: user.instaHandle ? user.instaHandle : "",
        venmo: user.venmo ? user.venmo : "",
        venmoLink: user.venmoLink ? user.venmoLink : "",
        cashAppLink: user.cashAppLink ? user.cashAppLink : "",
        cashAppName: user.cashAppName ? user?.cashAppName : "",
        zelleName: user.zelleName ? user.zelleName : "",
        zelleLink: user.zelleLink ? user.zelleLink : "",
        pdf: user.pdf ? user.pdf : "",
      });

      user.image && fetchUserImage(setImageLoading);
    }
  }, [user]);

  useEffect(() => {
    if (location?.state?.myValue) {
      setSelected("Your Subscription");
    }
  }, [location]);
  const [subscribing, setSubscribing] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    updateUser(
      { ...formData, venmo: formData.venmo ? formData.venmo : 0 },
      user.userId
    ).then((res) => {
      if (res.ok) {
        toast.success("User is updated successfully");
      } else {
        toast.error("Error while updating user");
      }
    });
  };

  const handleImageUpload = (data) => {
    uploadUserImage(data, user.userId);
  };

  const handleSubscribe = async (e, promo) => {
    e.preventDefault();
    let data = promo
      ? {
          promoCode: promo,
        }
      : {};

    // window.location.replace(res.url);
    await getSubscription(data)
      .then((res) => {
        if (res) {
          if (promo) {
            setShowSubscribe(false);
            toast.success("You have subscribed successfully!");
            loadUser();
          } else window.location.replace(res.url);
        }
      })
      .catch((error) => {
        console.log(error, "Error :::::::");
      });
  };

  const [title, setTitle] = useState("");
  const handleResetPassword = (data) => {
    resetAuthenticatedUserPassword(data);
  };

  const fetchUserImage = async () => {
    await toDataURL(user.image + "?x=1")
      .then((res) => {
        setSelectedImage(res);
        setImageLoading(false);
      })
      .catch((error) => {
        setImageLoading(false);
        console.error("Fetching image failed:", error.message);
      });
  };

  // useEffect(() => {
  //   user && user.image && fetchUserImage();
  // }, [selectedImage, user]);
  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const handleSubscribingClose = () => {
    setSubscribing(false);
  };

  const CancelSubscription = async () => {
    await cancelSubscription();
  };

  const inputRef = useRef();
  let arr = ["General", "Artist Info", "Tip Accounts", "Your Subscription"];

  const avatarUrl = useRef(
    "https://avatarfiles.alphacoders.com/161/161002.jpg"
  );
  const [modalOpen, setModalOpen] = useState(false);

  const updateAvatar = (imgSrc) => {
    avatarUrl.current = imgSrc;
  };

  return (
    <>
      {imageLoading ? (
        <div className="fixed w-full h-[100vh] top-0 left-0 bg-[rgba(0,0,0,0.95)] z-[777777777777777777] flex items-center justify-center">
          <BounceLoader color="gray" size={50} speedMultiplier={3} />
        </div>
      ) : (
        <section className="mt-12 mb-5 py-b px-4 lg:w-[90%] w-[92%] mx-auto min-h-screen">
          <h2 className="text-4xl font-semibold mb-2">Profile Settings </h2>

          <section className="grid  grid-cols-12 items-start md:gap-2 gap-5 min-h-screen">
            <section className="lg:col-span-4 sm:col-span-7 md:col-span-5 col-span-12">
              <section className="h-[50vh] border-[#303947] bg-[#22252A] border-2 rounded flex justify-center items-center flex-col gap-5">
                <img
                  className="xs:h-[200px] xs:w-[200px] w-[120px] h-[120px] rounded-full object-cover object-center border-2 border-grey"
                  src={
                    selectedImage
                      ? typeof selectedImage === "string" &&
                        selectedImage.startsWith("data:image/")
                        ? selectedImage // If it's a base64 string, use it directly
                        : URL.createObjectURL(selectedImage) // Otherwise, create a URL from the file object
                      : PLACEHOLDER_IMAGE
                  }
                  alt="profile"
                  // onClick={() => inputRef?.current?.click()}
                  onClick={() => setModalOpen(true)}
                  style={{ boxShadow: "0px 4px 35px rgba(251, 46, 51, 0.5)" }}
                />

                {modalOpen && (
                  <CenterPopup
                    dark={true}
                    popupstyles="relative !max-w-[55rem] !w-[95%] max-w-[1000px]  z !h-[600px]"
                  >
                    <p
                      className="p-[15px] rounded-full cursor-pointer right-2 top-2 bg-gray  w-10 h-10 flex items-center justify-center mb-3"
                      onClick={() => setModalOpen(false)}
                    >
                      <RxCross1 />
                    </p>
                    <ImageCropper
                      handleSubmit={handleSelectImage}
                      setModalOpen={setModalOpen}
                    />
                  </CenterPopup>
                )}
                <input
                  type="file"
                  accept="image/*"
                  hidden
                  ref={inputRef}
                  // value={selectedImage}
                  onChange={handleSelectImage}
                />
                <section className="flex flex-col gap-1 mt-3 ">
                  <h5 className="text-[20px] font-semibold">
                    {user?.firstName}{" "}
                    <span className="text-red">{user?.lastName}</span>
                  </h5>
                </section>
              </section>
            </section>
            <section className="lg:col-span-8 col-span-12 px-2 border-2 border-[#303947] rounded bg-[#22252A]">
              <section className="flex justify-around items-center sm:px-2 px-0 pt-5 border-b-2 border-gray">
                {arr.map((ele, index) => {
                  return (
                    <h2
                      key={index}
                      onClick={() => setSelected(ele)}
                      className={`${
                        ele === selected
                          ? "border-b-2 border-red text-white"
                          : "border-none text-[#C2C2C2]"
                      } sm:text-xl text-[14px] font-semibold cursor-pointer pb-3 `}
                    >
                      {ele}
                    </h2>
                  );
                })}
              </section>

              {selected === "General" && (
                <General
                  formData={formData}
                  setSelected={setSelected}
                  onChange={onChange}
                  handleImageUpload={handleImageUpload}
                  existingImage={existingImage}
                  handleResetPassword={handleResetPassword}
                  onSubmit={onSubmit}
                />
              )}
              {selected === "Artist Info" && (
                <Artist
                  formData={formData}
                  onChange={onChange}
                  setSelected={setSelected}
                  onSubmit={onSubmit}
                />
              )}
              {selected === "Tip Accounts" && (
                <Tip
                  formData={formData}
                  onChange={onChange}
                  onSubmit={onSubmit}
                  setSelected={setSelected}
                />
              )}

              {selected === "Your Subscription" && (
                <section className="h-[90vh] flex justify-center items-center flex-col gap-5">
                  <section className="bg-[#12141A] max-w-[400px] w-[96%] rounded-[20px] py-8  flex flex-col gap-5 items-center">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/subscribe.svg"
                      }
                      className="h-20 w-20 object-contain"
                    />

                    <section className="flex flex-col gap-3 items-center text-white">
                      {/* {user &&
                    user?.stripe_data?.subscription_next_date === null && (
                      <p>Subscription and Payment Info</p>
                    )} */}
                      {user &&
                        (user?.stripe_data?.subscription_id ||
                          user.subscription_expiry) && (
                          <>
                            <span className="text-[16px]">
                              {user.trial_used
                                ? "Your subscription will expire on"
                                : "Your Free  Trial mode will expire on"}
                            </span>
                            <span className="font-bold">
                              {moment(user.subscription_expiry).format(
                                "MM DD, YYYY"
                              )}
                            </span>
                          </>
                        )}

                      {(user &&
                        user?.stripe_data?.subscription_next_date === null) ||
                      user?.stripe_data?.subscription_next_date ===
                        undefined ? (
                        <button
                          className="bg-red w-[200px] p-1 rounded"
                          onClick={() => setSubscribing(true)}
                        >
                          Subscribe
                        </button>
                      ) : (
                        <button
                          className="py-2 bg-red px-4 rounded-lg"
                          onClick={CancelSubscription}
                        >
                          Cancel Subscription
                        </button>
                      )}
                    </section>
                  </section>
                </section>
              )}

              {subscribing && (
                <Popup
                  dark={true}
                  popupstyles="m-0 p-0"
                  setFunc={handleSubscribingClose}
                >
                  <form
                    onSubmit={onSubmit}
                    className="flex flex-col  gap-5  h-[100%] sm:pb-5 pb-10 text-white"
                  >
                    <h1 className="text-4xl font-bold bg-black text-white w-full px-5 pt-12 pb-4 shadow-md border border-[#303947]">
                      Promo <span className="text-red">code</span>
                    </h1>

                    <section className="flex-1 p-4">
                      <div className="flex flex-col text-white font-semibold w-full gap-1">
                        <label htmlFor="title">Promo code:</label>
                        <input
                          placeholder="input promo code"
                          name="title"
                          id="title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          className=" w-full border-[1px] border-[#616161] bg-[#323338] rounded py-2 px-4 font-medium placeholder:text-[#797A7D] placeholder:font-medium  outline-none "
                          required
                        />
                      </div>
                      <p
                        className="flex items-center mt-4 gap-2 justify-center text-[16px]  cursor-pointer"
                        onClick={(e) => {
                          handleSubscribe(e, null);
                          setSubscribing(false);
                        }}
                      >
                        <span>Continue Without Promo code</span>

                        <FaExternalLinkAlt color="#FB2E33" />
                      </p>
                    </section>

                    <section className="flex flex-col px-2 gap-2">
                      <button
                        className="bg-red p-1 rounded mx-2"
                        onClick={(e) => {
                          if (title) {
                            handleSubscribe(e, title);
                          }
                          setSubscribing(false);
                        }}
                      >
                        Subscribe
                      </button>
                    </section>
                  </form>
                </Popup>
              )}
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default observer(Settings);
