import React, { useEffect, useState } from "react";
import CenterPopup from "../layout/CenterPopup";
import { RxCross1 } from "react-icons/rx";
import { BounceLoader } from "react-spinners";
const About_06 = () => {
  const [isVideoVisible, setIsVideoVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [playVideo, setPlayVideo] = useState(true);
  const handleImageClick = () => {
    setIsVideoVisible(true);
  };

  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  return (
    <div
      className="h-full self-center flex justify-center w-full items-center min-h-full"
      // style={{ backgroundImage: `url(${image})`, backgroundSize: "cover" }}
    >
      {loading ? (
        <div className="fixed w-full h-[100vh] top-0 left-0 bg-[rgba(0,0,0,0.95)] z-[777777777777777777] flex items-center justify-center">
          <BounceLoader color="gray" size={50} speedMultiplier={3} />
        </div>
      ) : playVideo ? (
        <div className=" text-white overflow-hidden h-full flex flex-col items-center justify-center  xl:w-[80%] md:w-[800px] sm:w-[600px] w-[92%] md:text-[16px] text-[14px] gap-0">
          {/* <iframe
          src="https://www.youtube.com/embed/x3PUf0BLM3I?autoplay=1&mute=1&si=nBWwpQWfY3oxi2d3"
          title="YouTube video player"
          style={{ width: "100%", height: "60vh" }} // Adjust height as needed
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe> */}

          {/* <div className=" self-center  flex  items-center"> */}
          {/* <video
              src={process.env.PUBLIC_URL + "/assets/videos/intro_video.mp4"} // Replace with the path to your video file
              // autoPlay
              controls
              // muted // Most browsers require the video to be muted for autoplay
              loop // Optional: makes the video loop
              style={{
                display: "inline-block",
                borderRadius: 20,
              }} // Adjust height as needed
              // className="mx-auto w-[90%]  my-[20%] h-auto  !rounded-xl"
              // controls // Optional: add controls if you want the user to play/pause
            />
          </div> */}
          <iframe
            width="560"
            height="500"
            src={
              "https://www.youtube.com/embed/x3PUf0BLM3I?si=8mtEpDfff8YEiWqE&rel=0&autohide=1"
            }
            title="YouTube video player"
            style={{
              borderRadius: 12,
              maxWidth: 1400,
              width: "100%",
              height: "75vh",
            }}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerPolicy="strict-origin-when-cross-origin"
            allowFullScreen
          ></iframe>
          {/* </div> */}

          <h2 className="sm:text-3xl text-lg text-white text-start flex justify-start mt-4 w-full">
            NextQuest, The Bridge Between the Artist and Audience
          </h2>
          {/* </div> */}
          {/* <img
          src={process.env.PUBLIC_URL + "/assets/images/About_slider_bg_01.png"}
          height={500}
          className="w-full"
          alt="Image not Found"
          onClick={handleImageClick}
        /> */}
          {/* {isVideoVisible && (
          <CenterPopup dark={true}>
            <p
              className="p-[15px] rounded-full cursor-pointer right-2 top-2 bg-gray  w-10 h-10 flex items-center justify-center mb-3"
              onClick={() => setIsVideoVisible(false)}
            >
              <RxCross1 />
            </p>
            <div className="flex justify-center w-full">
              <iframe
                width="560"
                height="315"
                className="w-full"
                src="https://www.youtube.com/embed/kkOuRJ69BRY?si=lFDNGOUjT_SHO4Dz"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
              ></iframe>
            </div>
          </CenterPopup> */}
          {/* )} */}
        </div>
      ) : null}
    </div>
  );
};

export default About_06;
